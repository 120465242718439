import React from "react"

import DatasetDisplay from "../../components/DatasetDisplay"

// content specific imports
import DataUKSaaS from "../../data/data-UK-saas"
import ogImage from "../../images/data-points/45-fast-growth-b2b-saas-united-kingdom.png"

// tanstack table
import { createColumnHelper } from "@tanstack/react-table"

const columnHelper = createColumnHelper()
const columns = [
  columnHelper.accessor(row => row["Account"], {
    id: "accountName",
    cell: info => (
      <div className="max-w-[100px] truncate">
        <a
          href={info.row.original["Website"]}
          rel="nofollow"
          target="_blank"
          className="text-blue underline truncate"
        >
          {info.getValue()}
        </a>
      </div>
    ),
    header: "Company",
  }),
  columnHelper.accessor("Headquarters"),
  columnHelper.accessor("Founded"),
  columnHelper.accessor("Score", {
    cell: info => (
      <>
        <div
          className="-m-3 p-3"
          style={{
            backgroundColor: `hsl(100, 32%, ${120 - info.getValue() / 2}%)`,
          }}
        >
          {parseInt(info.getValue())}
        </div>
      </>
    ),
    header: "Tactic Score",
  }),
  columnHelper.accessor("B2B/B2C?"),
  columnHelper.accessor("Industry"),
  columnHelper.accessor("Headcount Total"),
  columnHelper.accessor("Recent funding date"),
  columnHelper.accessor("Recent funding series"),
  columnHelper.accessor("Company Description"),
]

const dataContext = {
  title: "45 Fast Growth B2B SaaS companies in the United Kingdom",
  description:
    "The top request on the Tactic platform is to find fast-growing B2B software companies. Here's a list of 45 such companies in the UK.",
  blurb:
    "The top request on the Tactic platform is to find fast-growing B2B software companies. Here's a list of 45 such companies in the UK. These companies are attractive targets due to their funding, their need for growth, and their level of innovation.",
  ctaText:
    "Get the whole list of 500+ UK B2B SaaS companies and sync it with your CRM.",
  slug: "45-uk-b2b-saas",
  freeLimit: 45,
  blogPost: (
    <>
      <h1 id="startups-in-the-uk">Startups in the UK</h1>
      <p>
        The UK has a thriving startup scene, with London as a global tech hub.
        Successful startups like Revolut, Deliveroo, Darktrace, and BenevolentAI
        have emerged. Government programs like EIS and SEIS, along with tax
        incentives, support these ventures. Universities, accelerators, and
        coworking spaces also contribute to startup growth.
      </p>
      <h1 id="what-is-saas-">What is SaaS?</h1>
      <p>
        B2B SaaS (Business-to-Business Software-as-a-Service) refers to
        cloud-based software solutions specifically designed for businesses.{" "}
      </p>
      <p>
        Commonly, people also use the term saas to mean companies that are
        funded by VC, growing fast, and generally innovative.{" "}
      </p>
      <p>
        It is this combination of factors that makes it difficult to define:
        what really is a B2B SaaS?
      </p>
      <p>This is how we have refined the search with Tactic.</p>
      <h1 id="how-we-created-this-list">How we created this list</h1>
      <p>
        Starting with a list of funded companies founded after 2008 (within the
        last 15 years), we considered companies with a scale of 50 to 5000
        employees, without filtering by industry. This approach ensures that we
        don&#39;t overlook any good B2B SaaS companies, even if they are
        classified in a different industry than “Information Technology”.
      </p>
      <p>
        We evaluated factors such as the presence of a &#39;contact sales&#39;
        or &#39;request demo&#39; button on the company website, as well as a
        dedicated pricing page, to narrow down the list. Tactic&#39;s LLMs
        helped us differentiate between B2B and B2C companies.{" "}
      </p>
      <p>
        Finally, we added more details about the company description, industry,
        headcount, and funding to ensure accurate and clean data.
      </p>
      <h1 id="how-we-ranked-and-prioritized-this-list">
        How we ranked and prioritized this list
      </h1>
      <p>
        “SaaS” companies are one of the most generated datasets on Tactic. Its
        in such high demand, we did some customer research about it.{" "}
      </p>
      <p>
        Turns out, SaaS is also a shortcut for customers to refer to venture
        capital funded, fast growth software companies.
      </p>
      <p>
        So, to produce this ranked list of companies, we went a much simpler
        path. Using Tactic’s scoring tool, we simply scored companies 50% on how
        recently they have been founded, and 50% on how big they got in that
        period.
      </p>
      <h1 id="how-many-vc-backed-b2b-saas-companies-are-there-in-the-uk-">
        How many VC backed B2B SaaS companies are there in the UK?
      </h1>
      <p>
        There are about 600 VC backed B2B SaaS companies in the United Kingdom,
        according to the criteria above – 50-5000 employees, founded after 2018,
        B2B, and offers cloud based software as a subscription.{" "}
      </p>
      <p>
        Here&#39;s the first 45 companies in this dataset. If you&#39;d like to
        ask additional questions (e.g. who has monthly pricing?), integrate this
        into your CRM, or access the rest of the dataset, please{" "}
        <strong>
          <a href="https://tactic.fyi/top-50-PLG-companies-in-europe/#cta">
            contact us for a chat
          </a>
        </strong>
        !
      </p>
      <aside className="p-4 border rounded">
        💡 <strong>A quick note about TAM (Total Addressable Market)</strong>
        <br />
        People often ask us how many companies like X are there in a region.
        This is a number that is charged with debate, and can be larger or
        smaller depending on how strict you are with your criteria. It is a
        hotly debated number because it feeds into discussions around quota
        planning and attainment, and investment into a market. 
      </aside>
    </>
  ),
  columns: columns,
  published: "Dec 1, 2023",
}

const DataSet = ({ location }) => {
  return (
    <DatasetDisplay
      location={location}
      ogImage={ogImage}
      dataset={DataUKSaaS}
      dataContext={dataContext}
    />
  )
}

export default DataSet
