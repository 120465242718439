const DataUKSaaS = [
    {
      "Account": "Bvnk",
      "Website": "http://www.bvnk.com",
      "Score": 100,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "financial services",
      "Headquarters": "United Kingdom",
      "Company Description": "BVNK is a payments platform for businesses that enables them to send, receive, and convert any currency at the speed of the internet. They offer a range of products including DLT Payments, Global Settlement, Virtual Accounts, and Embedded Payments. BVNK's payment rails unify traditional payment systems with distributed ledger technology, allowing businesses to build for the future. They serve various industries such as remittances, B2B payments, fintech, ecommerce, treasury, FX, gaming, institutional investing, and more.",
      "Founded": 2021,
      "Headcount Total": 304,
      "Recent funding date": "12/05/2022",
      "Recent funding series": "Series A"
    },
    {
      "Account": "Qogita",
      "Website": "http://www.qogita.com",
      "Score": 75,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "internet",
      "Headquarters": "United Kingdom",
      "Company Description": "Qogita is a one-stop wholesale marketplace for branded products. They connect buyers with thousands of suppliers, offering a catalog of 150,000+ SKUs and ‚Ç¨500M+ worth of stock. Their platform allows buyers to purchase products competitively priced and ready to ship in a few days. Qogita also offers financing and shipping insurance, making procurement quick and easy.",
      "Founded": 2021,
      "Headcount Total": 91,
      "Recent funding date": "02/05/2022",
      "Recent funding series": "Series B"
    },
    {
      "Account": "Accurx",
      "Website": "http://www.accurx.com",
      "Score": 75,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "information technology & services",
      "Headquarters": "United Kingdom",
      "Company Description": "Accurx is a platform that facilitates communication between patients and healthcare professionals. It offers a shared place and a shared way of communicating, which is quicker and more efficient than traditional methods like letters, phones, and pagers. Accurx aims to improve patient care by addressing the issue of poor communication in the healthcare system.",
      "Founded": 2016,
      "Headcount Total": 256,
      "Recent funding date": "15/09/2021",
      "Recent funding series": "Series B"
    },
    {
      "Account": "FintechOS",
      "Website": "http://www.fintechos.com",
      "Score": 75,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "information technology & services",
      "Headquarters": "United Kingdom",
      "Company Description": "FintechOS is a company that provides an end-to-end financial and insurance product innovation platform. They offer solutions for retail banking, small business banking, general insurance, embedded finance, health insurance, and life insurance. They also have partnerships and provide services and support for fintech innovation.",
      "Founded": 2017,
      "Headcount Total": 280,
      "Recent funding date": "01/01/2022",
      "Recent funding series": "Series B"
    },
    {
      "Account": "Threecolts",
      "Website": "http://www.threecolts.com",
      "Score": 75,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "",
      "Headquarters": "United Kingdom",
      "Company Description": "Threecolts is a company that offers a comprehensive cloud suite for Amazon businesses. They provide dozens of software products to help businesses manage their e-commerce channels such as Amazon, Walmart, and eBay. Their products include real-time listing and inventory alerts, multi-channel customer service integration, product review and seller feedback automation, profit analytics, support solutions built for e-commerce, and more.",
      "Founded": 2021,
      "Headcount Total": 108,
      "Recent funding date": "07/03/2023",
      "Recent funding series": "Series A"
    },
    {
      "Account": "Quantexa",
      "Website": "http://www.quantexa.com",
      "Score": 75,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "information technology & services",
      "Headquarters": "United Kingdom",
      "Company Description": "Quantexa is a company that provides a Decision Intelligence Platform to automate and augment decision-making. They offer solutions for data management, customer intelligence, risk management, financial crime, and more. They serve industries such as banking, insurance, government, and telecommunications.",
      "Founded": 2016,
      "Headcount Total": 672,
      "Recent funding date": "03/04/2023",
      "Recent funding series": "Series E"
    },
    {
      "Account": "Payhawk",
      "Website": "http://www.payhawk.com",
      "Score": 75,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "financial services",
      "Headquarters": "United Kingdom",
      "Company Description": "Payhawk is a company that offers a spend management solution for finance professionals. Their product combines corporate cards, expense management, and accounts payable into one platform. With Payhawk, businesses can control card spending at scale, automate expense management, and manage accounts payable efficiently. They also provide seamless integration with ERP systems and offer customizations for accounting and finance needs.",
      "Founded": 2018,
      "Headcount Total": 318,
      "Recent funding date": "01/03/2022",
      "Recent funding series": "Series B"
    },
    {
      "Account": "Graphcore",
      "Website": "http://www.graphcore.ai",
      "Score": 75,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "semiconductors",
      "Headquarters": "United Kingdom",
      "Company Description": "Graphcore is a company that accelerates machine learning in the cloud. They provide cloud services, pre-trained models, optimized inference engines, and APIs to build and deploy AI-native products and platforms. They offer IPUs in the cloud for industries like finance, biotech, scientific research, and AI-native startups.",
      "Founded": 2016,
      "Headcount Total": 406,
      "Recent funding date": "28/12/2020",
      "Recent funding series": "Series E"
    },
    {
      "Account": "Copper.co",
      "Website": "http://www.copper.co",
      "Score": 75,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "information technology & services",
      "Headquarters": "United Kingdom",
      "Company Description": "Copper is a company that provides institutional custody, prime services, and collateral management for digital assets. They offer secure custody solutions with MPC protected custodial architecture and multiple layers of security. They also provide trading services through their ClearLoop network, allowing institutional investors to access liquidity across multiple exchanges. Copper is trusted by industry leaders for their security and customer support.",
      "Founded": 2017,
      "Headcount Total": 279,
      "Recent funding date": "01/01/2021",
      "Recent funding series": "Series B"
    },
    {
      "Account": "Omnipresent",
      "Website": "http://www.omnipresent.com",
      "Score": 75,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "human resources",
      "Headquarters": "United Kingdom",
      "Company Description": "Omnipresent is a company that provides global employment solutions for distributed teams. They offer services such as Employer of Record (EOR), Professional Employer Organization (PEO), and Virtual Employer Organization (VEO). Omnipresent takes care of payroll, benefits, compliance, taxes, and administrative work, automating repetitive tasks like drafting local employment contracts and payroll calculations. They have been trusted by employers worldwide to employ their best talent. Based on their services and solutions, Omnipresent is likely a SaaS company.",
      "Founded": 2019,
      "Headcount Total": 325,
      "Recent funding date": "22/03/2022",
      "Recent funding series": "Series B"
    },
    {
      "Account": "The Ardonagh Group",
      "Website": "http://www.ardonagh.com",
      "Score": 75,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "insurance",
      "Headquarters": "United Kingdom",
      "Company Description": "The Ardonagh Group is one of the world's leading independent brokers, connecting clients and premium to global capital. They are a meeting point for best-in-class entrepreneurial and specialist brands. They have a diverse business portfolio and operate in multiple locations with over 10,000 employees.",
      "Founded": 2017,
      "Headcount Total": 1362,
      "Recent funding date": "15/12/2021",
      "Recent funding series": "Private Equity"
    },
    {
      "Account": "Anything World",
      "Website": "http://www.anything.world",
      "Score": 75,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "information technology & services",
      "Headquarters": "United Kingdom",
      "Company Description": "Anything World is a company that specializes in 3D animation and automated rigging. They offer game-ready SDKs, a 3D model library, and an AI Creator wizardry tool. Their proprietary Machine Learning algorithms can understand and add animations to almost any 3D model. They aim to supercharge game development and bring 3D animations to life at scale through the power of AI.",
      "Founded": 2018,
      "Headcount Total": 459,
      "Recent funding date": "09/11/2022",
      "Recent funding series": "Seed"
    },
    {
      "Account": "The Bank of London",
      "Website": "http://www.thebankoflondon.com",
      "Score": 75,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "banking",
      "Headquarters": "United Kingdom",
      "Company Description": "The Bank of London is a bank that offers business and corporate banking services, including opening bank accounts, embedded banking solutions, agency banking, clearing and payments. They also provide a developer studio for building solutions powered by their API. The company is focused on providing safer banking for business Britain by holding deposits in full and unencumbered at the Bank of England.",
      "Founded": 2021,
      "Headcount Total": 163,
      "Recent funding date": "08/02/2023",
      "Recent funding series": "Series C - II"
    },
    {
      "Account": "Hack The Box",
      "Website": "http://www.hackthebox.com",
      "Score": 75,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "computer & network security",
      "Headquarters": "United Kingdom",
      "Company Description": "Hack The Box is a platform that provides individuals, businesses, and universities with tools to continuously improve their cybersecurity capabilities. They offer a unified suite of hacking experiences, from beginners to seasoned professionals, and a huge library of educational content. They are trusted by ambitious cybersecurity teams and have been upskilling cyber superhumans since 2017.",
      "Founded": 2017,
      "Headcount Total": 1582,
      "Recent funding date": "11/01/2023",
      "Recent funding series": "Series B"
    },
    {
      "Account": "Synthesia",
      "Website": "http://www.synthesia.io",
      "Score": 75,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "information technology & services",
      "Headquarters": "United Kingdom",
      "Company Description": "Synthesia is an AI video generation platform that allows users to create professional videos without the need for mics, cameras, actors, or studios. They offer a range of features including AI avatars, AI voices in multiple languages, and pre-designed video templates. The company helps teams create training, sales, technical, and product videos at scale.",
      "Founded": 2017,
      "Headcount Total": 278,
      "Recent funding date": "13/06/2023",
      "Recent funding series": "Series C"
    },
    {
      "Account": "Vertice",
      "Website": "http://www.vertice.one",
      "Score": 75,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "financial services",
      "Headquarters": "United Kingdom",
      "Company Description": "Get granular visibility into your spend, and advanced forecasting. Control your SaaS and cloud usage with intelligent workflows. Save up to 20-30% annually. Guaranteed. All under one roof.",
      "Founded": 2022,
      "Headcount Total": 190,
      "Recent funding date": "19/05/2022",
      "Recent funding series": "Series A"
    },
    {
      "Account": "Zego",
      "Website": "http://www.zego.com",
      "Score": 75,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "insurance",
      "Headquarters": "United Kingdom",
      "Company Description": "Zego is an insurance company that provides smart insurance solutions for self-employed drivers and fleets. They offer flexible cover for private hire taxi drivers, all-in-one insurance for Uber drivers, app-based cover for good drivers, flexible car insurance for food delivery and courier work, flexible van cover for couriers and tradespeople, fleet insurance for fleets of any size and vehicle type, and flexible fleet insurance for electric scooters and e-bikes. They also partner with brokers and telematics providers. Zego's policies use smart technology to simplify the insurance process and save time and money for their customers.",
      "Founded": 2010,
      "Headcount Total": 387,
      "Recent funding date": "02/12/2021",
      "Recent funding series": "Series C"
    },
    {
      "Account": "Florence",
      "Website": "http://www.florence.co.uk",
      "Score": 75,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "hospital & health care",
      "Headquarters": "United Kingdom",
      "Company Description": "Florence is a company that revolutionizes social care staffing. They connect care professionals with open shifts through their apps.",
      "Founded": 2016,
      "Headcount Total": 473,
      "Recent funding date": "01/06/2022",
      "Recent funding series": "Series B"
    },
    {
      "Account": "Hopin",
      "Website": "http://www.hopin.com",
      "Score": 75,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "information technology & services",
      "Headquarters": "United Kingdom",
      "Company Description": "Hopin is a company that provides a suite of audience engagement tools, including StreamYard, Streamable, and Superwave (beta). Their products connect people, build community, and power business through live engagement and cutting-edge video technology.",
      "Founded": 2019,
      "Headcount Total": 364,
      "Recent funding date": "21/09/2021",
      "Recent funding series": "Series D"
    },
    {
      "Account": "Springpod",
      "Website": "http://www.springpod.com",
      "Score": 75,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "government administration",
      "Headquarters": "United Kingdom",
      "Company Description": "Springpod is a platform that provides an immersive introduction to working at top employers and universities. They offer work experience opportunities with world-leading organizations and allow users to try university courses before applying. Springpod is trusted by over 400,000 verified students and used by 4,000 schools and colleges globally.",
      "Founded": 2016,
      "Headcount Total": 475,
      "Recent funding date": "17/05/2023",
      "Recent funding series": "Series A"
    },
    {
      "Account": "Influencer",
      "Website": "http://www.influencer.uk",
      "Score": 63,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "marketing & advertising",
      "Headquarters": "United Kingdom",
      "Company Description": "Influencer is a global influencer marketing agency that harnesses the power of creators to drive True Human InfluenceTM. They are the originators of influencer marketing and work with brands, creators, and official partners. They design impactful campaigns that captivate attention, inspire action, and boost CTR, views, and conversions. They also provide marketing reports and have an Influencer Marketing Platform and Impact Studio to transform data into actionable strategies. Their team consists of dreamers, makers, and game changers reshaping the future of marketing.",
      "Founded": 2015,
      "Headcount Total": 626,
      "Recent funding date": "01/01/2021",
      "Recent funding series": "Seed"
    },
    {
      "Account": "LumiraDx",
      "Website": "http://www.lumiradx.com",
      "Score": 63,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "hospital & health care",
      "Headquarters": "United Kingdom",
      "Company Description": "LumiraDx is a company that provides next generation point of care diagnostic systems. They offer a small, portable instrument, microfluidic test strips, and seamless digital connectivity to the cloud and hospital IT systems. Their platform enables healthcare providers to deliver lab-comparable performance and real-time results at the point of care. LumiraDx aims to increase efficiency and affordability for healthcare providers globally.",
      "Founded": 2014,
      "Headcount Total": 683,
      "Recent funding date": "14/12/2022",
      "Recent funding series": "Grant"
    },
    {
      "Account": "Bud",
      "Website": "http://www.thisisbud.com",
      "Score": 63,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "financial services",
      "Headquarters": "United Kingdom",
      "Company Description": "Bud is an AI-powered data intelligence platform that helps financial institutions transform complex data into growth-accelerating financial solutions. They offer products like Drive, Engage, and Assess to boost customer engagement, provide data centricity, and make better lending decisions. Bud's solutions are scalable, secure, and trusted by businesses of all sizes. They have processed over 50 billion transactions and offer APIs and off-the-shelf products. Bud is likely a SaaS company because they provide software solutions as a service to financial institutions.",
      "Founded": 2015,
      "Headcount Total": 269,
      "Recent funding date": "08/06/2022",
      "Recent funding series": "Series B"
    },
    {
      "Account": "Matillion",
      "Website": "http://www.matillion.com",
      "Score": 63,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "information technology & services",
      "Headquarters": "United Kingdom",
      "Company Description": "Matillion is a productivity platform for data teams that enables users to move, transform, and orchestrate data pipelines. They offer a unified platform to build pipelines and deliver data at unlimited scale. Matillion is designed to make data work more productive by empowering both coders and non-coders. They provide a wide range of connectors and offer a code-optional platform that caters to various abilities. Matillion is likely a SaaS company because they offer a cloud-based platform that users can access and use on-demand.",
      "Founded": 2011,
      "Headcount Total": 554,
      "Recent funding date": "08/11/2022",
      "Recent funding series": "Series E"
    },
    {
      "Account": "Perspectum",
      "Website": "http://www.perspectum.com",
      "Score": 63,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "medical devices",
      "Headquarters": "United Kingdom",
      "Company Description": "Perspectum is a company that delivers advanced imaging solutions to help doctors make better decisions, personalize care for patients, and ensure better outcomes. They provide unmatched, integrated data intelligence by extracting biomarkers from imaging in an intuitive and streamlined way. Their imaging solutions are safe, fast, and offer comprehensive diagnostics. They have a patient-first approach and aim to enable healthcare providers to tailor precision medicine to the needs of the individual. Perspectum has proven leadership in healthcare and collaborates with world-leading experts for research on metabolic diseases, cancer, diabetes, and other chronic diseases.",
      "Founded": 2012,
      "Headcount Total": 263,
      "Recent funding date": "15/03/2023",
      "Recent funding series": "Series C"
    },
    {
      "Account": "Onfido",
      "Website": "http://www.onfido.com",
      "Score": 63,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "information technology & services",
      "Headquarters": "United Kingdom",
      "Company Description": "Onfido is a company that provides a complete, AI-powered digital identity solution to help businesses know their customers online. They offer automation to acquire new customers, reduce costs, and meet global KYC and AML compliance. Their solutions include the Real Identity Platform, Verification Suite, Onfido Studio, and Atlas AI.",
      "Founded": 2012,
      "Headcount Total": 588,
      "Recent funding date": "18/08/2020",
      "Recent funding series": "Grant (prize money)"
    },
    {
      "Account": "Unibuddy",
      "Website": "http://www.unibuddy.com",
      "Score": 63,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "higher education",
      "Headquarters": "United Kingdom",
      "Company Description": "Unibuddy is a company that provides a platform for student-to-student recruitment in higher education. Their platform allows universities and colleges to connect prospective students with current students, staff, or faculty members to engage authentically and personalize the recruitment process. By leveraging peer influence and providing insights, Unibuddy empowers students to make confident decisions about their future and helps institutions get better qualified applicants.",
      "Founded": 2015,
      "Headcount Total": 328,
      "Recent funding date": "07/07/2021",
      "Recent funding series": "Series B"
    },
    {
      "Account": "Tide",
      "Website": "http://www.tide.co",
      "Score": 63,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "financial services",
      "Headquarters": "United Kingdom",
      "Company Description": "Tide is a company that offers business banking services. They provide business accounts for freelancers, small businesses, and scaling businesses. Their accounts come with features such as accounting integrations, expense cards, invoicing, and 24/7 support. Tide also offers different account plans that scale with the business. They aim to save small businesses time and money by providing a free business bank account with no monthly fee. Based on the services they offer, Tide is likely a SaaS company as they provide software solutions for business banking.",
      "Founded": 2015,
      "Headcount Total": 1589,
      "Recent funding date": "12/07/2021",
      "Recent funding series": "Series C"
    },
    {
      "Account": "Soldo",
      "Website": "http://www.soldo.com",
      "Score": 63,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "financial services",
      "Headquarters": "United Kingdom",
      "Company Description": "Soldo is a company that provides prepaid company cards and an automated business expenses management platform. They help businesses simplify their spending by combining secure company cards with a powerful spend management platform. Soldo streamlines how budgets, spending, and reconciliation are managed.",
      "Founded": 2015,
      "Headcount Total": 350,
      "Recent funding date": "20/07/2021",
      "Recent funding series": "Series C"
    },
    {
      "Account": "GoCardless",
      "Website": "http://www.gocardless.com",
      "Score": 63,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "information technology & services",
      "Headquarters": "United Kingdom",
      "Company Description": "GoCardless is an online payment processing solution that offers recurring payments, invoice payments, and international payments. They provide a platform for businesses to collect payments from customers' bank accounts, reducing failed payments and increasing conversions.",
      "Founded": 2011,
      "Headcount Total": 815,
      "Recent funding date": "26/07/2022",
      "Recent funding series": "Series Unknown"
    },
    {
      "Account": "Paddle",
      "Website": "http://www.paddle.com",
      "Score": 63,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "information technology & services",
      "Headquarters": "United Kingdom",
      "Company Description": "Paddle is a complete payments, tax, and subscriptions solution for SaaS businesses. They provide an all-in-one payments infrastructure that handles billing, payments, tax compliance, and subscription management. Paddle acts as a merchant of record, taking on liability for charging and remitting sales taxes globally, as well as handling billing-related support queries and recovering failed payments to reduce churn. They have over 4,000 software businesses using their platform to scale their commercial operations.",
      "Founded": 2012,
      "Headcount Total": 333,
      "Recent funding date": "09/05/2022",
      "Recent funding series": "Series D"
    },
    {
      "Account": "Telcom",
      "Website": "http://www.telcom.uk",
      "Score": 63,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "telecommunications",
      "Headquarters": "United Kingdom",
      "Company Description": "Telcom is a company that provides reliable business internet and connectivity solutions. They offer a range of products and services including dedicated internet access, managed LAN and WiFi, managed firewall, cloud security, and unified communications. They also specialize in connecting multiple sites and upgrading commercial buildings with their Preconnect‚Ñ¢ solution. Telcom has built its own network and has a team of engineers to ensure fast and efficient installations. They work with businesses, landlords, and wholesale partners to provide hyperfast connectivity and industry-leading infrastructure.",
      "Founded": 2014,
      "Headcount Total": 351,
      "Recent funding date": "01/07/2021",
      "Recent funding series": "Series C"
    },
    {
      "Account": "Snyk",
      "Website": "http://www.snyk.io",
      "Score": 63,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "computer & network security",
      "Headquarters": "United Kingdom",
      "Company Description": "Snyk is a developer security platform that integrates directly into development tools, workflows, and automation pipelines. It helps teams find, prioritize, and fix security vulnerabilities in code, dependencies, containers, and infrastructure as code.",
      "Founded": 2015,
      "Headcount Total": 1121,
      "Recent funding date": "24/01/2023",
      "Recent funding series": "Series G"
    },
    {
      "Account": "Flagstone",
      "Website": "http://www.flagstoneim.com",
      "Score": 63,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "financial services",
      "Headquarters": "United Kingdom",
      "Company Description": "Flagstone is a UK-based cash deposit platform trusted by UK Banks and Wealth Managers. They provide a platform that allows individuals and businesses to easily split cash between multiple savings accounts from over 50 banking partners. They offer competitive interest rates and complete protection for deposited funds. Flagstone is not a SaaS company because they do not provide software as a service, but rather a platform for managing cash deposits.",
      "Founded": 2013,
      "Headcount Total": 251,
      "Recent funding date": "25/02/2022",
      "Recent funding series": "Angel"
    },
    {
      "Account": "Modulr",
      "Website": "http://www.modulrfinance.com",
      "Score": 63,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "financial services",
      "Headquarters": "United Kingdom",
      "Company Description": "Modulr is a company that provides embedded payment solutions for businesses in various industries such as accounting, travel, and financial services. They offer a suite of options to help businesses automate payments, leverage Open Banking technology, generate their own cards, and receive instant customer notifications. Modulr is likely a SaaS company because they provide scalable APIs and easy integration for businesses to access their payment functionality.",
      "Founded": 2010,
      "Headcount Total": 337,
      "Recent funding date": "18/05/2022",
      "Recent funding series": "Series C"
    },
    {
      "Account": "Builder.ai",
      "Website": "http://www.builder.ai",
      "Score": 63,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "information technology & services",
      "Headquarters": "United Kingdom",
      "Company Description": "",
      "Founded": 2016,
      "Headcount Total": 1523,
      "Recent funding date": "23/05/2023",
      "Recent funding series": "Series D"
    },
    {
      "Account": "Onclusive",
      "Website": "http://www.onclusive.com",
      "Score": 63,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "public relations & communications",
      "Headquarters": "United Kingdom",
      "Company Description": "Onclusive is a partner for success for PR and Communications teams. They work with leading brands and agencies to modernize their communications, increase performance, and demonstrate value. They provide a complete solution to manage pressroom, campaign planning, and media relationships.",
      "Founded": 2011,
      "Headcount Total": 895,
      "Recent funding date": "02/08/2022",
      "Recent funding series": "Private Equity"
    },
    {
      "Account": "Darktrace",
      "Website": "http://www.darktrace.com",
      "Score": 63,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "computer & network security",
      "Headquarters": "United Kingdom",
      "Company Description": "Darktrace is a cyber security company that provides real-time visibility and protection across multi-cloud environments. They offer a range of products including Darktrace PREVENT, Darktrace DETECT, Darktrace RESPOND, and Darktrace HEAL. Their AI-powered technology learns the organization's digital details to identify and prevent sophisticated cyber-attacks.",
      "Founded": 2013,
      "Headcount Total": 2482,
      "Recent funding date": "25/03/2020",
      "Recent funding series": "Series E"
    },
    {
      "Account": "ComplyAdvantage",
      "Website": "http://www.complyadvantage.com",
      "Score": 63,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "information technology & services",
      "Headquarters": "United Kingdom",
      "Company Description": "ComplyAdvantage is an AI-driven fraud and AML risk detection company. They offer solutions for sanctions and watchlists screening, adverse media screening, know your business (KYB), transaction monitoring, fraud detection, and payment screening. They serve industries such as banks, cryptocurrency, early-stage start-ups, corporates, insurance, lending, payments, and wealthtech and investments.",
      "Founded": 2014,
      "Headcount Total": 469,
      "Recent funding date": "20/05/2021",
      "Recent funding series": "Series C"
    },
    {
      "Account": "ESG Book",
      "Website": "http://www.esgbook.com",
      "Score": 50,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "financial services",
      "Headquarters": "United Kingdom",
      "Company Description": "ESG Book is a global leader in sustainability data and technology. They power financial markets for a sustainable future by providing framework-neutral ESG information in real-time and promoting transparency through their digital platform. They offer solutions for ESG data management, disclosure, and analytics, with access to ESG data for over 50,000 companies worldwide. Their suite of market-leading sustainability analytics enables clients to gain deeper insights into ESG risks and opportunities. They also provide a SaaS data management and corporate disclosure platform for seamless integration.",
      "Founded": 2017,
      "Headcount Total": 189,
      "Recent funding date": "22/06/2022",
      "Recent funding series": "Series B"
    },
    {
      "Account": "Rebellion Defense",
      "Website": "http://www.rebelliondefense.com",
      "Score": 50,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "information technology & services",
      "Headquarters": "United Kingdom",
      "Company Description": "Rebellion Defense is a company that builds the modern mission stack to detect and deter adversaries at scale. They believe warfighters must be equipped with the best software capabilities to detect, deter, and outpace the modern adversary at speed and scale. They bring together private sector technologists with defense veterans to build technology for defense and national security.",
      "Founded": 2019,
      "Headcount Total": 127,
      "Recent funding date": "13/09/2023",
      "Recent funding series": "Grant"
    },
    {
      "Account": "V7",
      "Website": "http://www.v7labs.com",
      "Score": 50,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "information technology & services",
      "Headquarters": "United Kingdom",
      "Company Description": "V7 is an AI data engine for computer vision and generative AI. They provide a platform for auto-annotation, DICOM annotation, dataset management, model management, image annotation, video annotation, workflows, document processing, labeling services, and more. They serve industries such as healthcare, retail, agriculture, and others. V7 offers features like performance analytics, real-time collaboration, CLI/SDK for AI training data, version control, and the ability to build custom workflows with humans and models. They also provide annotation services and open datasets. V7 is likely a SaaS company as they offer a platform for managing AI data and provide services through their platform.",
      "Founded": 2018,
      "Headcount Total": 120,
      "Recent funding date": "28/11/2022",
      "Recent funding series": "Series A"
    },
    {
      "Account": "birdie",
      "Website": "http://www.birdie.care",
      "Score": 50,
      "Tier": "B",
      "B2B/B2C?": "B2B",
      "Industry": "hospital & health care",
      "Headquarters": "United Kingdom",
      "Company Description": "Birdie is an all-in-one homecare software company that provides mobile and desktop software for managing care delivery, rostering, and invoicing. They offer tools for care management, rostering, finance, auditing, and connecting. Birdie aims to help care businesses save time and money, deliver outstanding care, and reduce risk. They also provide resources such as blogs, webinars, and a help center for care businesses.",
      "Founded": 2017,
      "Headcount Total": 190,
      "Recent funding date": "28/06/2022",
      "Recent funding series": "Series B"
    },
    {
      "Account": "Lemongrass",
      "Website": "http://www.lemongrasscloud.com",
      "Score": 50,
      "Tier": "B",
      "B2B/B2C?": "B2B",
      "Industry": "information technology & services",
      "Headquarters": "United Kingdom",
      "Company Description": "Lemongrass is a software-enabled services provider that specializes in SAP on Cloud. They offer end-to-end support for the entire customer lifecycle, including planning, migration, operation, and innovation. They partner with SAP, AWS, Microsoft, Google, and other global technology leaders. With one of the largest dedicated SAP on Cloud teams in the world, Lemongrass provides extended SAP capabilities and a strong, experienced, and flexible team.",
      "Founded": 2008,
      "Headcount Total": 584,
      "Recent funding date": "01/01/2021",
      "Recent funding series": "Series C"
    },
    {
      "Account": "Distributed",
      "Website": "http://www.distributed.com",
      "Score": 50,
      "Tier": "A",
      "B2B/B2C?": "B2B",
      "Industry": "information technology & services",
      "Headquarters": "United Kingdom",
      "Company Description": "Distributed is a company that offers Elastic Teams‚Ñ¢ through their Talent Cloud model. They curate a custom pool of experts to meet the specific requirements of organizations. The Elastic Teams can be leveraged by companies to build software better and faster. Distributed takes care of the operational logistics by vetting, onboarding, and paying the talent, allowing organizations to focus on delivering impact.",
      "Founded": 2017,
      "Headcount Total": 152,
      "Recent funding date": "30/06/2022",
      "Recent funding series": "Series Unknown"
    }
  ]

export default DataUKSaaS